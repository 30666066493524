<template>
  <div class="manage_project wrapper">
    <proTitle></proTitle>
    <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
      <div class="formInline-left">
        <el-form-item label="课程类型 :">
          <el-select v-model="formInline.pattern" placeholder="课程类型" @change="filterCourse()" style="width: 100px;">
            <el-option label="全部" value=""></el-option>
            <el-option label="同步课" value="0"></el-option>
            <el-option label="异步课" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" class="search">
          <el-input @input="filterCourse()" style="width:316px;" v-model="formInline.search" suffix-icon="el-icon-search"
            placeholder="请输入课程名称/授课老师/课程标签" size="mini"></el-input>
        </el-form-item>
      </div>
      <div class="formInline-right">
        <i class="el-icon-circle-plus-outline"></i>
        <span class="csp"
          @click="$router.push(`/course/create/${$store.state.user.currentProject.id}?breadNumber=4`)">创建课程</span>
      </div>
    </el-form>

    <div class="mid-bot">
      <el-table :data="tableData.list">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="id" width="80px" align="center" label="课程编号"></el-table-column>
        <el-table-column prop="name" label="课程名称" width="160px" align="center"></el-table-column>
        <el-table-column label="授课模式" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.lessonsPattern == 0 ? "同步课" : "异步课" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="timeScope" label="上课时间" width="190px" align="center"></el-table-column>
        <el-table-column prop="teacherName" label="授课老师" width="100px" align="center"></el-table-column>
        <el-table-column prop="assistantName" label="助教老师" width="80px" align="center"></el-table-column>
        <el-table-column label="班型" width="100px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lessonsPattern == 0">{{ classTypeMap[scope.row.classType] || '--' }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="操作" fixed="right" width="200px" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="toDetail(scope.row)">查看</el-button>
            <el-button type="text" @click="toEditor(scope.row)" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="isdelete(scope.row.id)">删除</el-button>
            <el-button type="text" size="small" @click="toShare(scope.row)">共享</el-button>
            <el-button type="text" size="small" @click="recommend(scope.row)">推荐</el-button>
            <br />
            <el-button type="text" size="small" @click="toLessonMange(scope.$index, scope.row)">课节管理</el-button>
            <el-button type="text" size="small" @click="toTeachingData(scope.row)">教学数据</el-button>
            <el-button type="text" size="small" @click="tips">监课评价</el-button>
            <el-button type="text" size="small" @click="setPermission(scope.row)"
              :disabled="scope.row.classType != 5">链接观看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize" :pageSizes="[8, 16, 24]" @pagination="handlePageChange">
      </pagination>
      <el-dialog title="链接观看" :visible.sync="dialogVisible" width="40%" center>
        <el-transfer filterable :filter-method="filterMethod" filter-placeholder="请输入搜索名称" v-model="value"
          :data="linkData" :titles="titles" :props="{ key: 'id', label: 'name' }"></el-transfer>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editPermission">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="推荐" :visible.sync="recommendDialogVisible" width="40%" center>
        <el-form :model="recommendForm" label-width="80px" label-position="left" style="width: 80%;">
          <el-form-item label="课程名称">
            <span>测试课程测试课程测试课程</span>
          </el-form-item>
          <div class="item-box" v-for="(item, index) in recommendForm.platArr" :key="index">
            <el-form-item label="平台">
              <div class="flex-align">
                <el-select v-model="item.platform" placeholder="请选择活动区域" size="medium"
                  @change="(val) => platformChange(val, index)">
                  <el-option :label="items.name" :value="items.id"
                    v-for="(items, index) in recommendForm.platformList"></el-option>
                </el-select>
                <i class="el-icon-circle-plus" style="font-size: 24px;margin-left: 15px;cursor: pointer;"
                  @click="addRecommendItem" v-if="index == 0"></i>
                <i class="el-icon-remove" style="font-size: 24px;margin-left: 15px;cursor: pointer;"
                  @click="delRecommendItem(index)" v-else></i>
              </div>
            </el-form-item>
            <el-form-item label="模块">
              <span v-if="item.modelList.length == 0" style="color: #909399;">请先选择推荐平台</span>
              <el-radio-group v-model="item.model" v-else>
                <el-radio :label="modelItem.id" :value="item.id" v-for="(modelItem, index) in item.modelList">{{
                  modelItem.name }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="recommendDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="recommendConfirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courseId: "", //当前选中的课程id
      formInline: { //搜索栏表单数据
        search: "", //模糊查询
        pattern: "" //课程类型
      },
      tableData: [], //项目课列表
      pages: { //分页
        currentPage: 1,
        pageSize: 8,
        total: 0,
      },
      classTypeMap: {
        2: '百家云大班普通科',
        3: '百家云大班伪直播',
        4: '百家云小班课',
        5: 'classin标准课',
        6: '腾讯云课堂',
      },
      dialogVisible: false,
      linkData: [],
      value: [],
      titles: ['客户端直播', '链接直播'],
      recommendDialogVisible: false,
      recommendForm: {
        platform: '', // 平台
        model: '', // 模块
        platformList: [
          {
            id: 0,
            name: '不推荐'
          }
        ], // 平台列表
        platArr: [
          {
            platform: '',
            model: '',
            modelList: []
          }
        ]
      },

    };
  },
  watch: {
    '$store.state.user.currentProject.id': {
      handler(newVal, oldVal) {
        console.log('watch监听')
        if (!newVal) return;
        this.pages.currentPage = 1;
        this.getcourseList();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    next(vm => {
      if (from.name == 'courseEdit' || from.name == 'lesson' || (from.matched.length > 0 && from
        .matched[0].name == 'lessonCreate')) {
        console.log('beforeRouteEnter')
        vm.getcourseList();
      }
    });
  },
  methods: {
    // 翻页
    handlePageChange(data) {
      this.pages.currentPage = data.pageIndex
      this.pages.pageSize = data.pageSize
      // console.log(this.pages);
      this.getcourseList();
    },
    // 监课评价提示
    tips() {
      this.$alert('暂无评价', '监课评价', {
        confirmButtonText: '确定'
      });
    },
    // 删除提示
    isdelete(id) {
      this.courseId = id;
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delCourse();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 删除课程
    async delCourse() {
      let resData = await this.$Api.Course.courseDel(this.courseId);
      if (resData.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success"
        });
        this.getcourseList();
      } else {
        this.$message.error(resData.msg);
      }
    },
    //课程列表
    async getcourseList() {
      let params = {
        page: this.pages.currentPage, // 页数
        limit: this.pages.pageSize, //每页个数
        projectId: this.$store.state.user.currentProject.id, //当前项目id
      };
      this.formInline.pattern ? params.lessonsPattern = this.formInline.pattern : ""; //筛选--授课模式类型
      this.formInline.search ? params.query = this.formInline.search : ""; //筛选--名称、老师、课程标签（模糊查询）
      let resData = await this.$Api.Course.courseLists(params);
      // console.log(resData);
      if (resData.data) {
        this.tableData = resData.data;
        this.pages.total = this.tableData.totalCount;
      } else {
        this.tableData = [];
      }
    },
    //跳转至课节管理
    toLessonMange(index, row) {
      this.$router.push(`/lesson/${row.id}?breadNumber=4`);
    },
    //跳转至教学管理
    toTeachingData(row) {
      // console.log(row)
      this.$router.push({
        path: `/teachingdata/${row.id}`,
        query: {
          type: row.lessonsPattern == 1 ? row.lessonsPattern : row.classType,
          breadNumber: 4,
        }
      });
    },
    // 跳转查看详情页面
    toDetail(row) {
      this.$router.push({
        path: `/course/detail/${row.id}`,
        query: {
          proId: this.$store.state.user.currentProject.id,
          breadNumber: 4,
        }
      });
    },
    // 跳转编辑页面
    toEditor(row) {
      this.$router.push({
        path: `/course/edit/${row.id}`,
        query: {
          proId: this.$store.state.user.currentProject.id,
          breadNumber: 4,
        }
      });
    },
    //  跳转分享页面
    toShare(row) {
      this.$router.push({
        path: `/course/share/${row.id}`,
        query: {
          proId: this.$store.state.user.currentProject.id,
          breadNumber: 4,
        }
      });
    },
    //筛选课程 [包括：筛选课程类型，模糊查询课程]
    filterCourse() {
      this.pages.currentPage = 1;
      this.getcourseList();
    },
    // 链接观看弹窗
    async setPermission(row) {
      // console.log('row:',row)
      this.courseId = row.id
      this.dialogVisible = true;
      let data = {
        page: 1,
        limit: 999,
        courseId: row.id
      }
      let resData = await this.$Api.Course.getUsersByCid(data);
      console.log('人员名单:', resData);
      this.linkData = resData.data.records;
      this.getPermission();
    },
    // 自定义搜索方法
    filterMethod(query, item) {
      return item.name.indexOf(query) > -1
    },
    // 编辑 classin使用 网页观看的人员
    async editPermission() {
      let data = {
        courseId: this.courseId,
        users: this.value
      }
      let resData = await this.$Api.Course.editPermission(data);
      console.log('编辑 classin使用 网页观看的人员:', resData);
      this.$message({
        message: '修改成功',
        type: 'success'
      });
      this.dialogVisible = false
    },
    // 推荐 
    async recommend(item) {
      console.log('item:', item);
      this.recommendForm.platformList = [
        {
          id: 0,
          name: '不推荐'
        }
      ];
      this.courseId = item.id;
      this.recommendDialogVisible = true;
      this.getRecommendPlatform();
      this.getCourseMark(item.id);
    },
    // 添加推荐项
    addRecommendItem() {
      let obj = {
        platform: '',
        model: '',
        modelList: []
      };
      this.recommendForm.platArr.push(obj)
    },
    // 删除推荐项
    delRecommendItem(index) {
      this.recommendForm.platArr.splice(index, 1)
    },
    // 获取推荐平台列表
    async getRecommendPlatform() {
      let resData = await this.$Api.Course.getRecommendPlatform();
      console.log("推荐平台:", resData);
      resData.data.map(item => {
        this.recommendForm.platformList.push(item);
      })
    },
    // 获取推荐平台模块
    async platformChange(id, index) {
      console.log('id:', id);
      // console.log('index:',index);
      this.recommendForm.platArr[index].model = '';
      let data = { id };
      let resData = await this.$Api.Course.getRecommendPlatform(data);
      console.log('该平台模块:', resData);
      this.recommendForm.platArr[index].modelList = resData.data;
    },
    // 确定推荐
    async recommendConfirm() {
      /**
       *  markId - 课程id
       *  targetId - 模块id
       *  targetType - 0
       */
      let arr = [];
      this.recommendForm.platArr.map(item => {
        if (item.model) {
          let obj = {
            markId: item.model,
            targetId: this.courseId,
            targetType: 0
          }
          arr.push(obj);
        }
      })
      let data = {
        courseId: this.courseId,
        markRelEntity: arr
      }
      let resData = await this.$Api.Course.addCourseMark(JSON.stringify(data));
      console.log('推荐结果:', resData);
      if (resData.code == 200) {
        this.$message({
          message: "推荐成功",
          type: "success"
        });
        this.recommendDialogVisible = false;
      } else {
        this.$message({
          message: "推荐失败，请联系管理员",
          type: "info"
        });
      }
    },
    // 推荐回显
    async getCourseMark(courseId) {
      let data = {
        courseId
      }
      let resData = await this.$Api.Course.getCourseMark(data);
      console.log('推荐回显:', resData);
      if (resData.data.length > 0) {
        this.recommendForm.platArr = [];
        resData.data.map((item, index) => {
          let obj = {
            platform: '',
            model: '',
            modelList: []
          };
          this.recommendForm.platArr.push(obj);
          this.platformChange(item.markParentId, index);
          this.recommendForm.platArr[index].platform = item.markParentId;
          this.recommendForm.platArr[index].model = item.markId;
        })
      }
    },

    // 回显 classin使用 网页观看的人员
    async getPermission() {
      let data = {
        courseId: this.courseId
      }
      let resData = await this.$Api.Course.getPermission(data);
      console.log('回显 classin使用 网页观看的人员:', resData);
      this.value = resData.data;
    }
  },
  mounted() {
    this.getcourseList();
  }
};
</script>

<style lang="less" scoped>
@import "../less/table.less";

.mid-bot {
  margin-top: 20px;

}

/deep/ .el-transfer-panel__item.el-checkbox .el-checkbox__label {
  overflow: visible;
}
</style>